import React from "react";
import styled from "styled-components";
import { shape, string, array } from "prop-types";
import Img from "./Image";
import ContentRow from "./ContentRow";
import ButtonLink from "./ButtonLink";

const StyledReferenceContainer = styled.div`
	display: flex;
	border-radius: 40px;
	padding: 60px 7% 60px 9%;
	@media (max-width: 750px) {
		padding: 40px 20px 60px 20px;
		flex-direction: column-reverse;
	}
	.reference__image {
		flex-grow: 1;
		@media (max-width: 750px) {
			margin: auto;
			width: 66%;
			max-width: 435px;
			padding-bottom: 30px;
		}
	}
	.reference__content {
		font-size: 16px;
		line-height: 26px;
		align-self: center;
		max-width: 55%;
		display: flex;
		flex-direction: column;
		padding-right: 45px;
		@media (max-width: 750px) {
			max-width: 100%;
			padding-right: 0;
		}
	}
	.reference__header {
		font-size: 36px;
		line-height: 44px;
		padding-bottom: 20px;
	}
	.reference__info {
		display: flex;
		@media (max-width: 550px) {
			flex-direction: column;
		}
		@media only screen and (min-width: 751px) and (max-width: 1100px) {
			flex-direction: column;
		}
		div:first-child {
			margin-left: 0;
		}
	}
	.reference__info__technology {
		display: flex;
		align-items: center;
		font-size: 0.7rem;
		margin-left: 30px;
		order: 2;
		@media (max-width: 550px) {
			margin-left: 0;
			order: 1;
			padding-bottom: 10px;
		}
		@media only screen and (min-width: 751px) and (max-width: 1100px) {
			margin-left: 0;
			order: 1;
			padding-bottom: 10px;
		}
		div:not(:first-child):before {
			content: "|";
			padding: 0.6rem;
		}
	}
	.reference__paragraph {
		padding-bottom: 20px;
	}
	.button-link {
		order: 1;
		margin: 0 !important;
		@media (max-width: 550px) {
			order: 2;
		}
		@media only screen and (min-width: 751px) and (max-width: 1100px) {
			order: 2;
		}
	}
`;

const ReferenceContainer = ({ data, ...rest }) => {
	const { header, button, content, image, technologies } = data;
	const settings = {
		maxWidth: "795px"
	};

	return (
		<ContentRow
			rowContentClass="no-padding"
			maxWidth={settings.maxWidth}
			{...rest}
			style={{
				marginBottom: "40px",
				padding: "0 20px"
			}}
		>
			<StyledReferenceContainer
				style={{
					backgroundColor: data.color.background,
					color: data.color.text,
					paddingBottom: data.bottom ? "60px" : "0px",
					alignItems: data.bottom ? "center" : "flex-end"
				}}
				breakpoint={settings.maxWidth * 0.9}
			>
				<div
					className="reference__content"
					style={{
						paddingBottom: data.bottom ? "0px" : "60px"
					}}
				>
					<h3 className="reference__header">{header}</h3>
					<p
						className="reference__paragraph"
						dangerouslySetInnerHTML={{ __html: content }}
					/>
					<div className="reference__info">
						{button.label && button.url && (
							<ButtonLink
								style={{
									backgroundColor: data.color.background,
									color: data.color.text,
									border: `1px solid ${data.color.text}`,
									borderRadius: "4px",
									padding: "12px 21px",
									textTransform: "uppercase",
									textDecoration: "none",
									fontSize: "14px",
									lineHeight: "18px",
									letterSpacing: "1px",
									height: "40px",
									width: "auto"
								}}
								link={button.url}
								label={button.label}
							/>
						)}
						{technologies && (
							<div className="reference__info__technology">
								{technologies.map(t => (
									<div>{t}</div>
								))}
							</div>
						)}
					</div>
				</div>
				<div className="reference__image">
					<Img
						durationFadeIn={200}
						objectPosition="50% 50%"
						objectFit="contain"
						data={image}
					/>
				</div>
			</StyledReferenceContainer>
		</ContentRow>
	);
};

ReferenceContainer.propTypes = {
	data: shape({
		header: string.isRequired,
		content: string.isRequired,
		image: shape({
			publicURL: string.isRequired,
			extension: string.isRequired
		}).isRequired,
		button: shape({
			url: string.isRequired,
			label: string.isRequired
		}),
		color: shape({
			text: string.isRequired,
			background: string.isRequired
		}),
		technologies: array.isRequired
	}).isRequired
};

export default ReferenceContainer;
