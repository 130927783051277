import React from "react";
import { graphql } from "gatsby";
import {
	shape,
	string,
	bool,
	object,
	array,
	objectOf,
	oneOfType,
	arrayOf
} from "prop-types";
import Layout from "../components/Layout";
import ContactRow from "../components/ContactRow";
import ContentRow from "../components/ContentRow";
import Main from "../components/Main";
import ReferenceContent from "../components/ReferenceContent";
import SEO from "../components/seo";
import RefHead from "../components/RefHead";
import HomeReferenceItems from "../components/HomeReferenceItems";
import ButtonHashLink from "../components/ButtonHashLink";
import HomeBottomBg from "../components/HomeBottomBg";

const Reference = ({ data, pageContext }) => (
	<Layout pageContext={pageContext}>
		<Main className="page-container">
			<SEO
				title={pageContext.REFERENCE_META_TITLE}
				description={pageContext.REFERENCE_META_DESCRIPTION}
				lang={pageContext.lang}
				keywords={["vývoj mobilních aplikací"]}
			/>
			<RefHead
				contentSize="big"
				headline={pageContext.REFERENCE_HEADLINE}
				content=""
				wrapperClass="reference-detail_head-section"
			/>
			{data.reference.references.map(item => (
				<ReferenceContent data={item} key={item.header} />
			))}
			<HomeBottomBg
				className="home_bottom-bg"
				id="blog"
				fluid={data.home_card_bg.childImageSharp.fluid}
			>
				<h2
					className="h1"
					style={{
						textAlign: "center",
						margin: "110px auto 0 auto",
						padding: "0 30px 20px 30px",
						maxWidth: "590px"
					}}
					dangerouslySetInnerHTML={{ __html: data.reference.postText }}
				/>
				<HomeReferenceItems data={data.homeReferenceItems.images} />

				{data.reference.button.label && data.reference.button.url && (
					<ContentRow
						style={{ marginBottom: "150px" }}
						rowContentClass="no-padding"
					>
						<ButtonHashLink
							classString="link green-font"
							style={{
								display: "block",
								width: "200px",
								textAlign: "center",
								margin: "20px auto 0 auto"
							}}
							link={data.reference.button.url}
							label={data.reference.button.label}
						/>
					</ContentRow>
				)}
			</HomeBottomBg>
			<ContactRow pageContext={pageContext} />
		</Main>
	</Layout>
);

Reference.propTypes = {
	pageContext: objectOf(oneOfType([string, bool, object, array])).isRequired,
	data: shape({
		reference: shape({
			references: arrayOf(
				shape({
					header: string.isRequired,
					content: string.isRequired,
					button: shape({
						url: string.isRequired,
						label: string.isRequired
					}),
					image: shape({
						extension: string.isRequired,
						publicURL: string.isRequired
					}),
					bottom: bool.isRequired,
					color: shape({
						text: string.isRequired,
						background: string.isRequired
					})
				}).isRequired
			)
		}).isRequired
	}).isRequired
};

export default Reference;

export const query = graphql`
	query ReferenceQuery($lang: String!) {
		home_card_bg: file(relativePath: { eq: "home-card-bg-hd.png" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		homeReferenceItems: homeReferenceItemsJson {
			images {
				img {
					publicURL
					extension
					childImageSharp {
						fixed(height: 250) {
							...GatsbyImageSharpFixed_withWebp_noBase64
						}
					}
				}
				alt
			}
		}
		reference: referenceIntroJson(lang: { eq: $lang }) {
			postText
			button {
				url
				label
			}
			references {
				header
				content
				button {
					url
					label
				}
				image {
					extension
					publicURL
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
				bottom
				color {
					text
					background
				}
				technologies
			}
		}
	}
`;
