import styled from "styled-components";

const StyledContactForm = styled.div`
	@media only screen and (min-width: 2350px) {
	}

	.reference-detail_head-section {
		margin: auto;
		text-align: center;
		width: 600px;
		padding: 85px 0 50px 0;
	}
	@media only screen and (max-width: 1200px) {
		.reference-detail_head-section {
			margin: auto;
			text-align: center;
			width: 100%;
			padding: 50px 30px;
			box-sizing: border-box;
		}
	}
`;

export default StyledContactForm;
