import React from "react";
import PropTypes from "prop-types";
import ContentRow from "../ContentRow";
import CommonContent from "../CommonContent";
import StyledRefHead from "./styles";

const RefHead = ({ headline, content }) => (
	<StyledRefHead>
		<div style={{ height: "80px" }} />
		<ContentRow background="white" rowContentClass="no-padding">
			<CommonContent
				headline={headline}
				contentSize="big"
				content={content}
				wrapperClass="reference-detail_head-section"
			/>
		</ContentRow>
	</StyledRefHead>
);

RefHead.propTypes = {
	headline: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired
};

export default RefHead;
